import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Link from "../../components/custom/Link";
import LoadingAnimation from "../../components/custom/LoadingAnimation";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { BUILDER_URL, setImageSrc } from "../../utils/config";
import "swiper/swiper.min.css";
import Image from "next/image"; // Import Next.js Image component

SwiperCore.use([Navigation, Pagination, Autoplay]);

const BASE_URL = BUILDER_URL;

const Collage = ({ widgetId, onImageLoad }) => {
  const theme = useContext(ThemeContext);
  const [collageData, setCollageData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { collage_structure, collage_image, widget_title } = collageData || {};

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 300);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageLoaded = (event) => {
    const loadingAnimation = event.currentTarget?.previousSibling;
    if (loadingAnimation) {
      loadingAnimation.style.display = "none";
    }
  };

  useEffect(() => {
    if (widgetId) {
      axios
        .get(`${BASE_URL}/collage/${widgetId}`, {
          headers: {
            Authorization: `Bearer ${theme.token}`,
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setCollageData(response.data[0]);
          }
        })
        .catch((error) => {
          console.error("Error fetching collage data:", error);
          setCollageData(null);
        });
    }
  }, [widgetId]);

  if (!collageData) return null;

  const isMobile = windowWidth <= 1024;

  const renderSwiper = (images) => (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      pagination={{ clickable: true }}
    >
      {images.map((imageData, index) => (
        <SwiperSlide key={index}>
          <Image
            className="object-cover w-full mt-2"
            src={setImageSrc(imageData.image_url, "")}
            alt={`Jewelry item ${index + 1}`}
            width={500} // Adjust the width as necessary
            height={500} // Adjust the height as necessary
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <>
      {collageData?.collage_structure !== null ? (
        <div className="mb-2">
          <span className="text-xl lg:text-2xl px-3 lg:px-0 lg:mb-4 font-semibold text-primary text-center flex justify-center">
            {widget_title}
          </span>

          {collage_structure === 0 &&
            (isMobile ? (
              renderSwiper(collage_image.slice(0, 1))
            ) : (
              <div
                className={`lg:mb-4 ${
                  collageData?.is_box_width === 1
                    ? "block xl:container px-4"
                    : " flex justify-center items-center lg:block"
                }`}
              >
                {/* rounded md set to parent div - 29/08/2024 */}
                <Link href={collage_image[0].image_link} className="rounded-md">
                  <Image
                    src={setImageSrc(collage_image[0].image_url, "")}
                    onLoad={onImageLoad}
                    layout="responsive" // Ensures the image scales correctly
                    width={1920} // Set the desired width for the banner image
                    height={1080} // Set the desired height for the banner image
                    loading="lazy"
                  />
                </Link>
              </div>
            ))}

          {collage_structure === 1 && (
            <div
              className={`mt-4 ${
                collageData?.is_box_width === 1 ? "block xl:container px-4" : ""
              }`}
            >
              <div className="grid md:grid-cols-12 sm:grid-cols-1 gap-2">
                <div className="md:col-span-7">
                  {/* rounded md set to parent div - 29/08/2024 */}
                  <Link
                    href={collage_image[0].image_link}
                    className="md:rounded-md"
                  >
                    <Image
                      className="object-cover w-full h-full"
                      src={setImageSrc(collage_image[0].image_url, "")}
                      alt="Jewelry main"
                      width={700} // Adjust the width as necessary
                      height={900} // Adjust the height as necessary
                    />
                  </Link>
                </div>
                <div className="md:col-span-5 flex flex-col gap-2">
                  {collage_image.slice(1, 4).map((imageData, index) => (
                    <div key={index}>
                      {/* rounded md set to parent div - 29/08/2024 */}
                      <Link
                        href={imageData.image_link}
                        className="cursor-pointer md:rounded-md "
                      >
                        <Image
                          className="shimmer object-cover w-full h-full"
                          src={setImageSrc(imageData.image_url, "")}
                          alt={`Jewelry item ${index + 1}`}
                          width={500} // Adjust the width as necessary
                          height={300} // Adjust the height as necessary
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {collage_structure === 2 && (
            <div
              className={`mt-4 ${
                collageData?.is_box_width === 1
                  ? "block xl:container px-4"
                  : "w-full"
              } min-h-[520px] `}
            >
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                <div className="">
                  {/* rounded md set to parent div - 29/08/2024 */}
                  <Link
                    className="md:rounded-md"
                    href={collage_image[0].image_link}
                  >
                    <Image
                      className=" object-cover w-full h-full"
                      src={setImageSrc(collage_image[0].image_url, "")}
                      alt="Primary Jewelry Image"
                      width={600} // Adjust the width as necessary
                      height={900} // Adjust the height as necessary
                    />
                  </Link>
                </div>
                <div className="">
                  <div className="grid grid-cols-2 gap-2">
                    {collage_image.slice(1, 5).map((imageData, index) => (
                      <div key={index} className="">
                        {/* rounded md set to parent div - 29/08/2024 */}
                        <Link
                          href={imageData.image_link}
                          className="md:rounded-md  cursor-pointer"
                        >
                          <Image
                            className="object-cover w-full h-full"
                            src={setImageSrc(imageData.image_url, "")}
                            alt={`Secondary Jewelry Image ${index + 1}`}
                            width={300} // Adjust the width as necessary
                            height={300} // Adjust the height as necessary
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {collage_structure === 3 && (
            <div
              className={`mt-4 ${
                collageData?.is_box_width === 1 ? "block xl:container px-4" : ""
              }`}
            >
              <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-2">
                {collage_image.slice(0, 3).map((imgObj, index) => (
                  <div key={index}>
                    {/* rounded md set to parent div - 29/08/2024 */}
                    <Link href={imgObj.image_link} className="md:rounded-md">
                      <Image
                        className=" pr-1 object-cover h-full w-full"
                        src={setImageSrc(imgObj.image_url, "")}
                        alt={`Jewelry item ${index + 1}`}
                        width={400} // Adjust the width as necessary
                        height={600} // Adjust the height as necessary
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        false
      )}
    </>
  );
};

export default Collage;
